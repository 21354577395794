<template>
  <div>
    <div class="pl-3 pr-3 pt-3 pb-3">
      <h4 class="m-0">Documents</h4>
      <div class="text-muted">
        <small>Documents du système qualité Arkadia Group</small>
      </div>
    </div>
    <div class="pl-3 pr-3 d-none d-md-block">
      <h5 class="m-0">Catégories</h5>
      <LeftBarItem text="Récents" link="recents"></LeftBarItem>
      <LeftBarItem text="Tous les documents" link="all"></LeftBarItem>
      <LeftBarItem text="Procédures" link="procedures"></LeftBarItem>
      <LeftBarItem text="Formulaires" link="formulaires"></LeftBarItem>
      <LeftBarItem text="Supports" link="supports"></LeftBarItem>
      <LeftBarItem text="Trames" link="trames"></LeftBarItem>
    </div>
    <div class="pt-3 pl-3 pr-3 d-none d-md-block">
      <h5
        class="m-0 cursor-pointer"
        @click="showActivites = !showActivites"
        :class="{ 'font-weight-bold': showActivites }"
      >
        Activités
      </h5>
      <div v-if="showActivites">
        <LeftBarItem text="Assistance technique" link="assistance_technique"></LeftBarItem>
        <LeftBarItem text="Etudes forfaitaires" link="etudes"></LeftBarItem>
        <LeftBarItem text="Contrôle non destructif" link="cnd"></LeftBarItem>
        <LeftBarItem text="Scan 3D" link="scan"></LeftBarItem>
      </div>
    </div>
    <div class="pt-3 pl-3 pr-3 d-none d-md-block">
      <h5
        class="m-0 cursor-pointer"
        @click="showMangement = !showMangement"
        :class="{ 'font-weight-bold': showMangement }"
      >
        Thèmes
      </h5>
      <div v-if="showMangement">
        <LeftBarItem text="Organisation" link="organisation"></LeftBarItem>
        <LeftBarItem text="Consignes générales" link="consignes"></LeftBarItem>
        <LeftBarItem text="Santé, sécurité et environnement" link="sse"></LeftBarItem>
        <LeftBarItem text="Radioprotection" link="radioprotection"></LeftBarItem>
        <LeftBarItem text="Ressources Humaines" link="rh"></LeftBarItem>
        <LeftBarItem text="Achats et fournisseurs" link="achat"></LeftBarItem>
        <LeftBarItem text="Documents pour EDF" link="edf"></LeftBarItem>
        <LeftBarItem text="Documents pour le CEA" link="cea"></LeftBarItem>
      </div>
    </div>
    <div class="pt-3 pl-3 pr-3 d-none d-md-block">
      <h5
        class="m-0 cursor-pointer"
        @click="showRaccourcis = !showRaccourcis"
        :class="{ 'font-weight-bold': showRaccourcis }"
      >
        Raccourcis
      </h5>
      <div v-if="showRaccourcis">
        <LeftBarItem text="Organigrammes" link="organigrammes"></LeftBarItem>
        <LeftBarItem text="Nouvel embauché" link="embauche"></LeftBarItem>
        <LeftBarItem text="Plaquettes et publicités" link="pub"></LeftBarItem>
        <LeftBarItem text="Déclarer un accident" link="accidents"></LeftBarItem>
        <LeftBarItem text="Déclarer une non conformité" link="non-conformites"></LeftBarItem>
        <LeftBarItem text="Flash et communication SSER" link="sser"></LeftBarItem>
        <LeftBarItem text="Visite et audit de chantier" link="audit"></LeftBarItem>
        <LeftBarItem text="Animation SSER (causerie...)" link="animation"></LeftBarItem>
      </div>
    </div>
    <div class="pt-3 pl-3 pr-3 d-none d-md-block">
      <h5
        class="m-0 cursor-pointer"
        @click="showRaccourcis2 = !showRaccourcis2"
        :class="{ 'font-weight-bold': showRaccourcis2 }"
      >
        Qualité
      </h5>
      <div v-if="showRaccourcis2">
        <LeftBarItem text="Certificats" link="certificats"></LeftBarItem>
        <LeftBarItem text="Référentiels" link="referentiel"></LeftBarItem>
      </div>
    </div>
    <div class="p-3 d-none d-md-block">
      <h5
        class="m-0 cursor-pointer"
        @click="showRaccourcis2 = !showRaccourcis2"
        :class="{ 'font-weight-bold': showRaccourcis2 }"
      >
        Administratif
      </h5>
      <div v-if="showRaccourcis2">
        <LeftBarItem text="KBIS" link="kbis"></LeftBarItem>
        <LeftBarItem text="Administratif ENERGY" link="administratif_energy"></LeftBarItem>
        <LeftBarItem text="Administratif TECHNO" link="administratif_techno"></LeftBarItem>
        <LeftBarItem text="Administratif NDT" link="administratif_ndt"></LeftBarItem>
        <LeftBarItem text="Administratif SI" link="administratif_si"></LeftBarItem>
        <LeftBarItem text="Administratif SERVICES" link="administratif_services"></LeftBarItem>
      </div>
    </div>
  </div>
</template>
<script>
import LeftBarItem from "@/components/documents/LeftBarItem.vue";

export default {
  components: {
    LeftBarItem,
  },
  data() {
    return {
      showActivites: true,
      showMangement: true,
      showRaccourcis: true,
      showRaccourcis2: true,
    };
  },
};
</script>
